.v-tabs{
  header{
    user-select: none;
    cursor: pointer;
    border-top:1px solid #D9D9D9;
    .row{
      min-height: 35px;
    }
    &.opened{
      box-shadow: 0 3px 12px rgba(21,88,146,0.25);
      border-top:1px solid transparent;
      transition: 0.1s linear;
    }
    padding: 10px 20px;
    .h-icon{
        width: 70px;
    }
  }
}


.h-tabs{
  .nav-tabs{
    background: transparent;
  }
  .nav-tabs .nav-link{
    border-radius: 0;
    border:none;
    border-top: 3px solid transparent;
    background: $gray-dark-3;
    padding:10px 15px;
    &,& *{
      user-select: none;
      cursor: pointer;
    }
    &.active{
      @extend .bg-gray;
      border-top-color: #0CB7EF;
    }
  }
  .nav-item{
    display: inline-flex;
  }
}