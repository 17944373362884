
body {
  font-family: Roboto, Arial;
  font-weight: 400;
  line-height: 1;
}

/*colors*/
.bg-blue {
  background-color: $blue;
}

.bg-blue-light {
  background-color: $blue-light;
}

.bg-blue-dark {
  background-color: $blue-dark;
}

.bg-yellow {
  background-color: $yellow;
}

.bg-yellow-light {
  background-color: $yellow-light;
}

.bg-red {
  background-color: $red;
}

.bg-green {
  background-color: $green;
}

.bg-gray {
  background-color: $gray;
}

.bg-gray-dark {
  background-color: $gray-dark;
}

.bg-gray-dark-1 {
  background-color: $gray-dark-1;
}

.bg-gray-super-light {
  background-color: $bg-gray-super-light;
}

.bg-white {
  background-color: white;
}

.bg-contain, .bg-image {
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-image {
  background-position: center;
}

.bg-contain {
  background-size: contain;
}

/*textos*/
.txt-blue {
  color: $blue;
}

.txt-blue-light {
  color: $blue-light;
}

.txt-blue-light-1 {
  color: $blue-light-1;
}

.txt-blue-dark {
  color: $blue-dark;
}

.txt-yellow {
  color: $yellow;
}

.txt-red {
  color: $red;
}

.txt-green {
  color: $green;
}

.txt-gray {
  color: $gray;
}

.txt-gray-dark {
  color: $gray-dark;
}

.txt-gray-dark-2 {
  color: $gray-dark-2;
}

.txt-white {
  color: #FFFFFF;
}

/*Fonts*/
.font-raleway {
  font-family: Raleway, sans-serif;
}

.font-roboto {
  font-family: Roboto, Arial;
}

.font-regular {
  font-weight: 400;
}

.font-bold {
  font-weight: 700;
}

.font-black {
  font-weight: 900;
}

.font-20 {
  font-size: 20px;
}

.font-16 {
  font-size: 16px;
}

.font-15 {
  font-size: 15px;
}

.font-14 {
  font-size: 14px;
}

.font-13 {
  font-size: 13px;
}

.font-12 {
  font-size: 12px;
}

.font-11 {
  font-size: 11px;
}

.font-10 {
  font-size: 10px;
}

/*spaces*/
.margin-auto {
  margin: auto;
}

.no-margin {
  margin: 0 !important;
}

.no-padd {
  padding: 0 !important;
}

.big-padd-lr {
  padding-left: 60px;
  padding-right: 60px;
}

.med-padd {
  padding: 30px;
}

.med-padd-l {
  padding-left: 30px
}

.med-padd-r {
  padding-right: 30px;
}

.med-padd-t {
  padding-top: 30px;
}

.med-padd-b {
  padding-bottom: 30px;
}

.med-padd-bt, .med-padd-tb {
  @extend .med-padd-t;
  @extend .med-padd-b;
}

.med-padd-lr, .med-padd-rl {
  @extend .med-padd-l;
  @extend .med-padd-r;
}

.padd {
  padding: 15px;
}

.padd-l {
  padding-left: 15px
}

.padd-r {
  padding-right: 15px;
}

.padd-t {
  padding-top: 15px;
}

.padd-b {
  padding-bottom: 15px;
}

.padd-bt, .padd-tb {
  @extend .padd-t;
  @extend .padd-b;
}

.padd-lr, .padd-rl {
  @extend .padd-l;
  @extend .padd-r;
}

.mid-padd {
  padding: 7.5px;
}

.mid-padd-l {
  padding-left: 7.5px
}

.mid-padd-r {
  padding-right: 7.5px;
}

.mid-padd-t {
  padding-top: 7.5px;
}

.mid-padd-b {
  padding-bottom: 7.5px;
}

.mid-padd-bt, .mid-padd-tb {
  @extend .mid-padd-t;
  @extend .mid-padd-b;
}

.mid-padd-lr, .mid-padd-rl {
  @extend .mid-padd-l;
  @extend .mid-padd-r;
}

.min-padd {
  padding: 5px;
}

.min-padd-l {
  padding-left: 5px
}

.min-padd-r {
  padding-right: 5px;
}

.min-padd-t {
  padding-top: 5px;
}

.min-padd-b {
  padding-bottom: 5px;
}

.min-padd-bt, .min-padd-tb {
  @extend .min-padd-t;
  @extend .min-padd-b;
}

.min-padd-lr, .min-padd-rl {
  @extend .min-padd-l;
  @extend .min-padd-r;
}

.special-padd {
  padding: 20px;
}

.special-padd-l {
  padding-left: 20px;
}

.special-padd-r {
  padding-right: 20px;
}

.special-padd-t {
  padding-top: 20px;
}

.special-padd-b {
  padding-bottom: 20px;
}

.special-padd-bt, .special-padd-tb {
  @extend .special-padd-t;
  @extend .special-padd-b;
}

.special-padd-lr, .special-padd-rl {
  @extend .special-padd-l;
  @extend .special-padd-r;
}

/*Titles*/
h1, h2, h3, h4, p {
  @extend .no-margin;
}

h1 {
  @extend .font-raleway;
  @extend .font-black;
}

h2, h3, h4 {
  @extend .font-roboto;
  @extend .font-bold;
}

h1, h4 {
  @extend .font-16;
}

h2, h3 {
  @extend .font-14;
}

/*paragrah*/
p {
  @extend .no-margin;
  line-height: 1.5;
}

.line-1 {
  line-height: 1;
}

.line-1-2 {
  line-height: 1.2;
}

.line-1-3 {
  line-height: 1.3;
}

.line-1-4 {
  line-height: 1.4;
}

.line-1-5 {
  line-height: 1.5;
}

/*border-radius*/
.b-rad-5 {
  border-radius: 5px;
}

.b-rad-10 {
  border-radius: 5px;
}

.b-rad-rounded {
  border-radius: 50%;
}

.b-rad-none {
  border-radius: 0;
}

/*Modal text*/
.modal-content {
  p {
    line-height: 1.3;
  }

  ul {
    margin: 0;
    display: block;
    margin-block-start: 0;
    padding-inline-start: 25px;
  }

  li {
    padding: 2px 0;
  }

  li {
    margin-left: 5px;
  }
}

.modal-body {
  max-height: 75vh;
  overflow-y: auto;
}


/*Extras*/
.pointer {
  cursor: pointer;
}

.wide {
  width: 100%;
}

.App {
  max-width: 768px;
  margin: auto;
}

.no-undeline {
  text-decoration: none !important;
}

.Footer {
  color: #687B8D;
}

.Separator {
  border-top: 1px solid #C0D3E3;
  height: 1px;
  width: 100%;

  &.blue {
    border-top: 1px solid $blue;
  }

  &.gray {
    border-top: 1px solid #E0E0E0;
  }
}

.NoBorderCard {
  box-shadow: 0 2px 6px rgba(21, 88, 146, 0.25);
}

.carousel-mid-padd-lr {
  padding-left: 10px;
  padding-right: 10px;
}

.margin-auto {
  margin: auto;
}
