$blue: #155892;
$blue-light: #0CB7EF;
$blue-light-1: #8CE0FC;
$blue-dark: #1E3C5A;
$yellow: #FFA401;
$yellow-light: #FFDB9A;
$red: #FF562E;
$green: #13AD2B;
$gray: #DCE7F0;
$gray-dark: #799BB7;
$gray-dark-1: #C0D3E3;
$gray-dark-2: #97AEC4;
$gray-dark-3: #C3D5E4;
$bg-gray-super-light: #EDEFF0;