.custom-css-class {
  display: flex;
  position: fixed;
  z-index: 1;
  bottom: 0;
  background-color: white;
  width: 100%;
  max-width: 768px;

  .wrap {
    width: 100%;
  }

  .progess {
    width: 100%;
    height: 5px;
    display: flex;

    .first-step, .second-step {
      width: 50%;
      height: 100%;
      background-color: #FFA401;
    }
  }

  .note {
    width: 90%;
    margin-top: 12px;
    border-color: #C0D3E3;
    margin-bottom: 12px;
    height: 140px;
    border-radius: 1.8px;
  }

  fieldset {
    margin-left: auto;
    margin-right: auto;

    .close-icon {
      font-size: 24px;
      position: absolute;
      right: 7px;
      top: 5px;
      color: #1E3C5A;
    }

    .wizzard-survey {
      padding-bottom: 19px;
      margin-top: 5px;
    }
  }

  .form-group.field {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .blue-line {
    height: 4px;
    margin-top: 14px;
    margin-bottom: 11px;
    width: 59px;
    background-color: #D8E7F3;
  }

  .form-group.field .control-label {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: bold;
    color: #1E3C5A;
  }

  .btn.btn-secondary {
    background-color: #0CB7EF;
    font-family: "Roboto";
    font-weight: bold;
    font-size: 1rem;
  }


  .successful {
    display: flex;
    font-family: 'Roboto';
    font-weight: bold;
    .icon {
      font-size: 65px;
      color: #0CB7EF;
      margin-right: 16px;
    }

    h3 {
      color: #0CB7EF;
      font-size: 22px;
    }

    h5 {
      font-size: 15px;
      color: #1E3C5A;
      font-weight: bold;
    }

    .body {
      width: 135px;
    }

  }

  .body-text {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 14px;
    color: #799BB7;

    span {
      color: #0CB7EF;
      cursor: pointer;
    }
  ;
  }

}


.btn.btn-info {
  visibility: hidden;
}


%visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.star-rating {
  &__star {
    display: inline-block;
    padding: 3px;
    vertical-align: middle;
    line-height: 1;
    font-size: 1.75em;
    color: #D8E7F3;
    transition: color 0.2s ease-out;

    &:hover {
      cursor: pointer;
    }

    &.is-selected {
      color: #ffd700;
    }

    &.is-disabled:hover {
      cursor: default;
    }
  }

  &__checkbox {
    @extend %visually-hidden;
  }
}
