#documents{
  .see-document-text{
    position:relative;
    top:1px
  }
  header {
    background: $blue-dark;
    .header-marker>div{
      font-family: Raleway;
      font-weight: 900;
      text-transform: uppercase;
      color: $yellow;
    }
    &:not(.opened){
      border-bottom: 1px solid $blue-light;
    }
  }
  .icon-marker{
    color: white;
  }
  .documentValue{
    word-wrap: break-word;
  }
}

