.capitalize-first-letter:first-letter {
  text-transform: uppercase !important;
}

.technical-review-items-container {
  & > div:last-child .Separator {
    display: none;
  }
}

.rev-tech-txt-gray{
  color:#6A6A6A;
}