.Menu{
  position: fixed;
  top:0;
  width: 100%;
  max-width: 768px;
  margin: auto;
  height: 60px;
  background: white;
  z-index: 5;
  box-shadow: 0 3px 10px rgba(21, 88, 146, 0.15);
  img.logo{
    width: 120px;
    margin: 7.5px 15px;
  }
  .title{
    position: relative;
    top:7.5px;
  }
 .menu-icon{
   position: absolute;
   right: 0;
   top:0;
   width: 60px;
   height: 60px;
   padding: 23px 22px;
   div{
     height: 5px;
     border-top: 2px solid #FFA401;
   }
 }
  @media (max-width: 370px) {
    .title{
      .strong{
        font-size: 15px;
      }
      span{
        font-size: 12px;
      }
    }
    img.logo{
      margin:7.5px;
    }
    .menu-icon{
      width: 50px !important;
      padding: 23px 17px !important;
    }
  }
}
.MenuOptions{
  img{
    margin: 0 !important;
  }
  display: flex;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  border-bottom: 1px solid $gray;
  margin-top: 7.5px;
  .RightSide{
    width: 300px;
  }
  .social-container{
    position: relative;
    .separator{
      position: absolute;
      height: 32px;
      left:125px;
      top:10px;
      border-left: 1.2px solid #6388A7;
      width: 2px;
    }
  }
}
.MenuDataDetail{
  border-bottom: 1px solid #C0D3E3;
  .point{
    div{
      height: 6px;
      width: 6px;
      border-radius: 50px;
      background: $gray-dark;
      margin: 4px 10px 0 0;
    }
  }
  .menu-item{
    &:first-letter{
      text-transform: capitalize;
    }
  }
}
.MenuItemsContainer{
  height: calc(100vh - 60px);
  overflow-y: auto;
}
.GoToDesktop{
  position: fixed;
  top:60px;
  width:100%;
  max-width: 768px;
  margin: auto;
  z-index: 1;
  box-shadow: 0 3px 12px rgba(21, 88, 146, 0.27);
}