//Animations
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.animated.fast {
  animation-duration: 0.5s;
}

@keyframes lightSpeedIn {
  from {
    transform: translate3d(200%, 0, 0) skewX(-30deg);
    opacity: 0;
  }

  60% {
    transform: skewX(20deg);
    opacity: 1;
  }

  80% {
    transform: skewX(-5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.spin {
  animation-name: spin;
  animation-timing-function: linear;
  animation-duration: 1s;
}
@keyframes unspin {
  100% {
    transform: rotate(-360deg);
  }
}
.unspin {
  animation-name: unspin;
  animation-timing-function: linear;
  animation-duration: 1s;
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}


//loading
#loading {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #DCE7F0;

  &.medium {
    min-height: 200px;
    margin: auto;
    svg {
      height: 40px;
      width: 40px;
    }
  }

  &.big {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    svg {
      height: 50px;
      width: 50px;
    }
  }

}

.spinner {
  animation: rotator 1.4s linear infinite;
}

.spinner .path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite,
  colors 2.8s ease-in-out infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes colors {
  0% {
    stroke: #E6B54A;
  }
  100% {
    stroke: #145A87;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

//loading
