.Menu{
  position: fixed;
  top:0;
  left: 0;
  right:0;
  height: 60px;
  background: white;
  z-index: 5;
  box-shadow: 0 3px 10px rgba(21, 88, 146, 0.15);
  img{
    width: 120px;
    margin: 7.5px 15px;
  }
  .title{
    position: relative;
    top:7.5px;
  }
 .menu-icon{
   width: 60px;
   height: 60px;
   padding: 23px 22px;
   div{
     height: 5px;
     border-top: 2px solid #FFA401;
   }
 }
}