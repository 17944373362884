.modal{
  .modal-backdrop.show{
    opacity: 0.74;
  }
  .modal-content{
    border-radius: 10px;
  }
  .modal-center{
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0 auto;
    padding: 15px;
    align-items: center;
  }
  .modal-bottom{
    @extend .modal-center;
    align-items: flex-end;
  }
  .modal-header,.modal-body,.modal-footer{
    padding: 0;
  }
  .modal-content{
    padding: 15px;
  }
  button.close{
    position: relative;
    top:-8px;
    right: -1px;
    outline: none !important;
    color: #799BB7;
    font-size: 28px;
    opacity: 1;
    font-weight: bold;
  }
  .modal-header{
    border-bottom: 2px solid #C0D3E3;
    margin-bottom:15px;
    padding-bottom: 5px;
  }

  .inverted{
    max-width: 300px;
    .modal-content{
      border-radius: 0;
      padding: 0;
    }
    .modal-body{
      padding: 10px;
    }
    .modal-header,.modal-title{
      border: none;
      border-radius: 0;
      margin: 0;
    }
    .modal-header{
      padding: 10px 15px 0;
      background: $blue;
      color: white;
    }
    .modal-title{
      color: white;
    }
    button.close{
      color: white;
      margin: 0;
      padding: 0;
      font-size: 20px;
      top:-6px;
      right: -3px;
    }
  }
}
