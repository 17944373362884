/*
* boostrap
*/
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
//@import "~bootstrap/scss/tables";
//@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
//@import "~bootstrap/scss/dropdown";
//@import "~bootstrap/scss/button-group";
//@import "~bootstrap/scss/input-group";
//@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
//@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/breadcrumb";
//@import "~bootstrap/scss/pagination";
//@import "~bootstrap/scss/badge";
//@import "~bootstrap/scss/jumbotron";
//@import "~bootstrap/scss/alert";
//@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
//@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
//@import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/popover";
//@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
//@import "~bootstrap/scss/print";
/*
* boostrap
*/

//plugins
@import "styles/variables";
@import "styles/animations";
@import "styles/common";


//components
@import "components/Icons/Icons";
@import "components/Tabs/Tabs";
@import "components/Cards/Cards";
@import "components/Buttons/Buttons";
@import "components/Carousel/Carousel";
@import "components/Modals/Modals";
@import "components/Texts/Texts";
@import "components/Patente/Patente";

//containers
@import "containers/AppMenu/Menu";
@import "containers/AppFooter/Footer";
@import "containers/VehicleData/styles/VehicleData";
@import "containers/OwnerRecords/styles/OwnersRecords";
@import "containers/VehicleSituation/styles/index";
@import "containers/TechnicalReview/styles/TechnicalReview";
@import "containers/TechnicalReviewRecords/styles/TechnicalReviewRecords";
@import "containers/AppTipsManager/TipsManager";
@import "containers/VehiclesFleet/styles/VehiclesFleet";
@import "containers/NotifiedServices/styles/NotifiedServices";
@import "containers/OnCameraInfractions/styles/OnCameraInfractions";
@import "containers/PaidInfractions/styles/PaidInfractions";
@import "containers/Recalls/styles/Recalls";
@import "containers/Documents/styles/index";
@import "containers/Survey/styles/index";

