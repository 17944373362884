/*HorizontalTabs*/
.btn {
  @extend .font-15;
  font-family: Roboto, Arial;
  box-shadow: 0 3px 10px rgba(21, 88, 146, 0.17) !important;
  outline: none !important;
  border: none !important;
  &:disabled, &.disabled {
    background-color: #D3D3D3 !important;
    @extend .no-shadows;
  }
}

.no-shadows {
  box-shadow: none !important;
}

.btn-primary {
  @extend .bg-blue;
  @extend .font-bold;
  @extend .no-shadows;
  border-radius: 5px;
  padding: 5px 30px;
}

.btn-secondary {
  background-color: #799BB7;
}

.btn-success {
  background-color: #F0F8FD;
  color: #687B8D !important;
  &:not(:disabled):not(.disabled):active, &:hover {
    background-color: white;
  }
}

.btn-primary, .btn-secondary {
  &:not(:disabled):not(.disabled):active, &:hover {
    background-color: #4496DD;
  }
}

.btn-secondary, .btn-success {
  border-radius: 10px;
  padding: 8.5px 30px;
}

.btn-light-container {
  &, & * {
    user-select: none;
    @extend .pointer;
  }
  border-radius: 30px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 240px;
  height: 53px;
  transition: width 1s;
  overflow: hidden;
  background-color: #FFDB9A;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  &.shadows:not(.hide) {
    box-shadow: 0 3px 10px #FFA500;
  }
  .btn-light {
    @extend .b-rad-rounded;
    color: inherit;
    font-size: inherit;
    background-color: #FFA401 !important;
    padding: 11px;
  }
  .txt-ctn {
    padding: 2px 10px 0;
    a, a:hover {
      text-decoration:none !important;
      color: $blue !important;
    }
    &.hide {
      display: none !important;
    }
  }
  &.hide-text {
    width: 53px;
    transition: width 1s;
  }
}
