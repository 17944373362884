.patente-signal {
  border-radius: 5px;
  .letter {
    font-size: 32px;
  }
  .icon-1 {
    div {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #000;
    }
  }
  .icon-2 img {
    width: 12px;
  }
  .country-name {
    padding-top: 4px;
    font-size: 10px;
    letter-spacing: 8px;
  }
  .icon-3 {
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: #000;
  }
  &.mini {
    img {
      margin: 0 !important;
    }
    font-weight: 400;
    .letter {
      font-size: 24px;
    }
    .country-name {
      font-size: 8px;
    }
    .icon-2 img {
      width: 8px;
    }
    .icon-1 {
      div {
        width: 3px;
        height: 3px;
      }
    }
  }
}