.carousel {
  overflow-x: scroll;
  scrollbar-width: none;

  &, * {
    user-select: none;
  }
}

.scroll-indicator {
  border-radius: 30px;
  overflow-x: hidden;
  -ms-overflow-style: none; //hide the scroll
  overflow: -moz-scrollbars-none; //hide the scroll
  scrollbar-height: none;
  background: #B2CADE;

  &, .indicator {
    height: 6px;
  }
}

::-webkit-scrollbar { //hide the scroll
  display: none;
}

.show-arrows {
  @extend .clearfix;
  position: relative;

  > div {
    float: left;
  }

  .carousel-body {
    width: calc(100% - 68px);
    background:#C3D5E4;
  }

  .left-arrow, .right-arrow {
    cursor: pointer;
    text-align: center;
    width: 34px;
    >div{
      margin: auto;
    }
  }

  .arrow {
    position: absolute;
    top: calc(50% - 10px);
  }
}