.TextLimiter {
  position: relative;
  overflow: hidden;

  .limiter {
    position: absolute;
    bottom: 0;
    height: 150px;
    width: 100%;
    background: linear-gradient(transparent, $gray, $gray);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 30px;
  }

  &.unlimited {
    transition: height 0.5s ease-in-out;

    .limiter {
      display: none;
    }
  }
}

.CurrentTag {
  padding: 2px 6px 1px 5px;
  border-radius: 15px;

  .text {
    padding-left: 3px;
    padding-top: 1px;
  }
}

.WarningText {
  .rounded-circle {
    position: relative;
    bottom: 4px;
  }
}