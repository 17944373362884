/*icons*/
[class~=icon-]{
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.icon-xs{
  width: 15px;
  height: 15px;
  svg{
    width: 7px !important;
    height: 7px !important;
  }
}
.icon-md{
  width: 20px !important;
  height: 20px;
  svg{
    width: 10px !important;
    height: 10px !important;
  }
}
.adjust-icon{
  position: relative;
  top: -4px;
}
.adjust-icon-reverse{
  position: relative;
  top: 2px;
}
