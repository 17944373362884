.badge-card {
  @extend .bg-white;
  @extend .b-rad-10;
  user-select: none;
  position: relative;
  box-shadow: 0 2px 6px rgba(21, 88, 146, 0.25);
  width: 300px;
  @media (max-width: 390px) {
    width: 260px;
  }
  @media (max-width: 330px) {
    width: 230px;
  }

  .badge {
    @extend .txt-white;
    @extend .font-bold;
    background-color: #C0D3E3;
    position: absolute;
    border-radius: 30px;
    top: -11px;
    font-size: 13px;
    padding: 3px 6px;
    border: 1px solid white;
  }
}

.wide-card {
  width: 380px;
  max-width: 100%;
}

.see-more-btn-container {
  height: 60px;
}

.see-more-btn {
  position: absolute;
  bottom: 0;
  width: 100%;

  & > * {
    border-radius: 5px;
    user-select: none;
    cursor: pointer;
    padding: 10px;
  }

  &.display-as-disabled > * {
    background: #97AEC4;
  }
}

.display-inside.see-more-btn {
  padding: 0 0 17.5px 0;
  margin-top: -12.5px;
  text-align: center;

  & > * {
    position: initial;
    width: calc(100% - 30px);
    margin: auto;
    display: inline-block;
  }

  & > * {
    text-decoration: none !important;
    color: white !important;
  }

}