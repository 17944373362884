.NotifiedServicesSection {
  .notified-services-detail:last-child {
    padding-bottom: 0;
    .col-6:first-child {
      padding-bottom: 2px;
    }
    .col-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .carousel-container:last-child{
    .Separator.manageable{
      border:none;
    }
  }
  .record-place-img{
    border:1px solid #EDEDED;
  }
}